/* Global Styling here */
html {
    height: 100%;
}

body {
    --background-color-offset: 373px;

    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
}

/* Used for gray background on selector step */
body::after {
    content: '';
    display: block;
    background: #fff;
    transition: background-color 0.2s ease;
    height: calc(100% - (var(--background-color-offset)));
    width: 100%;
    top: var(--background-color-offset);
    position: absolute;
    z-index: -1
}

/* Google Places Autocomplete */
.pac-container {
    z-index: 5000 !important;
}